<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">html</div>
        <div class="module-preview--contents">
            click to edit html content.
        </div>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";

export default {
    mixins: [ Widget ],
}
</script>

<style scoped>
.module-preview--contents{
    grid-template-columns: 1fr auto;
}
</style>